/***
 * openLayers基础工具
 *
 * 此工具类旨在于方便使用 openLayers，只提供了一些基础的，常用的工具方法，一些较为深入的用法请阅读官方api
 *
 * 官网地址： {@link https://openlayers.org/}
 *
 * API地址： {@link https://openlayers.org/en/latest/apidoc/}
 *
 * 官方示例： {@link https://openlayers.org/en/latest/examples/}
 *
 * 另外，工具方法上都有注释，食用前请仔细阅读，以免中毒 (￣︶￣)↗
 *
 * @version 1.0.1
 * @author instanr
 * @since 2021-07-20
 */
import 'ol/ol.css';
import { Map, Overlay, View } from "ol";
import { Tile, Vector } from "ol/layer";
import { XYZ, WMTS, TileWMS as WMS, OSM, Tile as TileSource, BingMaps } from "ol/source";
import { Vector as VectorSource } from "ol/source";
import { GeoJSON, EsriJSON, TopoJSON, GML, Polyline } from "ol/format";
import { Style, Fill, Stroke, Text, Circle, Icon, RegularShape } from "ol/style";
import { Draw } from "ol/interaction";
import * as sphere from 'ol/sphere';
import { getCenter, getWidth } from "ol/extent";
/***
 * 创建地图
 * 传入两个参数：viewOption 和 mapOption；其中mapOption中的target参数必填，其他具体参数说明请移步官网
 * @param option {@link createMapOption} 创建地图的必要参数
 * @return Object {map, view}
 *
 *
 * map： {@link https://openlayers.org/en/latest/apidoc/module-ol_Map-Map.html}
 *
 * view： {@link https://openlayers.org/en/latest/apidoc/module-ol_View-View.html}
 */
export function createMap(option) {
    var view = new View(option.view);
    option.map.view = view;
    var map = new Map(option.map);
    return {
        view: view,
        map: map
    };
}
/***
 * 创建并返回一个网格的source对象
 *
 * 参照官网 {@link https://openlayers.org/en/latest/apidoc/module-ol_source_Tile-TileSource.html}
 *
 * 其余的类型也可参照，因为基本都是继承 TileSource 对象
 *
 * @param sourceType {TileSourceFormat} source的类型
 *
 * @param sourceOption {Object} 传入的参数
 */
export function getTileSource(sourceType, sourceOption) {
    switch (sourceType) {
        case "OSM":
            return new OSM(sourceOption);
        case "XYZ":
            return new XYZ(sourceOption);
        case "Tile":
            return new TileSource(sourceOption);
        case "WMS":
            return new WMS(sourceOption);
        case "WMTS":
            return new WMTS(sourceOption);
        case "Bing":
            return new BingMaps(sourceOption);
    }
}
/***
 * 创建一个网格图层
 * @param tileOption {@link createTileLayerOption}
 */
export function createTileLayer(tileOption) {
    if (tileOption.option === undefined) {
        tileOption.option = {};
    }
    if (tileOption.sourceOption instanceof TileSource) {
        tileOption.option.source = tileOption.sourceOption;
    }
    else {
        tileOption.option.source = getTileSource(tileOption.sourceType, tileOption.sourceOption);
    }
    return new Tile(tileOption.option);
}
/***
 * 获取一个矢量的Source
 * @param sourceType {@link VectorSourceFormat} 矢量图层的类型
 *
 * @param sourceOption {@link VectorSourceOption} source创建时的参数，参照官网 {@link https://openlayers.org/en/latest/apidoc/module-ol_source_Vector-VectorSource.html}
 *
 * @param formatOption {@link Object} 对应类型的参数 参照官网 {@link https://openlayers.org/en/latest/apidoc/module-ol_format_GeoJSON-GeoJSON.html} 注：不同的类型（sourceType）都有不同的formatOption 需根据不同的类型传入不同的参数，对应的网站也不同，请仔细辨别
 *
 * @param feature {@link string } | {@link Object} 需要读取的对象， 可以是任意参数，也是根据不同的类型传入的，如类型是GeoJson，则传入的就应该是geoJson对象，次参数为readFeatures()方法的source参数，如需传入其他参数，请创建完source对象或Layer对象后自行修改，其余请阅读官方API
 */
export function getVectorSource(sourceType, sourceOption, formatOption, feature) {
    if (sourceOption === undefined) {
        sourceOption = {};
    }
    switch (sourceType) {
        case "EsriJSON":
            sourceOption.features = new EsriJSON(formatOption).readFeatures(feature);
            break;
        case "GeoJSON":
            sourceOption.features = new GeoJSON(formatOption).readFeatures(feature);
            break;
        case "GML":
            sourceOption.features = new GML(formatOption).readFeatures(feature);
            break;
        case "TopoJSON":
            sourceOption.features = new TopoJSON(formatOption).readFeatures(feature);
            break;
        case "Polyline":
            sourceOption.features = new Polyline(formatOption).readFeatures(feature);
            break;
        case "GPX":
        case "IGC":
        case "KML":
        case "MVT":
        case "OSMXML":
        case "WFS":
        case "WKB":
        case "WKT":
        case "XML":
        case "NONE":
            return new VectorSource();
        default:
            return new VectorSource();
    }
    return new VectorSource(sourceOption);
}
/***
 * 创建并返回一个矢量图层对象
 * TODO createVectorLayerOption除了手动维护的属性值，其他值没有拷贝（例如：zIndex）
 * 参照官网 {@link https://openlayers.org/en/latest/apidoc/module-ol_layer_Vector-VectorLayer.html}
 *
 * @param createOption {@link createVectorLayerOption} 请看上面的type
 *
 * @return VectorLayer {@link Vector}
 */
export function createVectorLayer(createOption) {
    var trueOption = {};
    if (createOption.sourceOption == undefined) {
        createOption.sourceOption = {};
    }
    if (createOption.sourceOption instanceof VectorSource) {
        trueOption = Object.assign(trueOption, { source: createOption.sourceOption });
    }
    else {
        trueOption = Object.assign(trueOption, { source: getVectorSource(createOption.sourceType, createOption.sourceOption, createOption.formatOption, createOption.feature) });
    }
    if (createOption.option == undefined) {
        createOption.option = {};
    }
    if (createOption.option.style) {
        trueOption = Object.assign(trueOption, { style: formatStyle(createOption.option.style) });
    }
    return new Vector(trueOption);
}
/***
 * 创建一个 Style对象
 * TODO StyleOption除了手动维护的属性值，其他值没有拷贝（例如：zIndex）
 * @param option {@link StyleOption} 分解参数具体请参照官网 {@link https://openlayers.org/en/latest/apidoc/module-ol_style_Style-Style.html}
 *
 * @return Style {@link Style}
 */
export function formatStyle(option) {
    var trueOption = {};
    if (option.fill) {
        trueOption = Object.assign(trueOption, { fill: new Fill({ color: option.fill }) });
    }
    if (option.stroke) {
        trueOption = Object.assign(trueOption, { stroke: new Stroke(option.stroke) });
    }
    if (option.image) {
        if (option.image) {
            if (option.image.circle) {
                var circleOption = {
                    fill: new Fill({ color: option.image.circle.fill }),
                    radius: option.image.circle.radius || 0,
                    stroke: new Stroke(option.image.circle.stroke),
                    displacement: option.image.circle.displacement,
                    scale: option.image.circle.scale,
                    rotation: option.image.circle.rotation,
                    rotateWithView: option.image.circle.rotateWithView,
                };
                trueOption = Object.assign(trueOption, { image: new Circle(circleOption) });
            }
            if (option.image.shape) {
                var shapeOption = {
                    fill: new Fill({ color: option.image.shape.fill }),
                    points: option.image.shape.points,
                    radius: option.image.shape.radius || 0,
                    radius1: option.image.shape.radius1 || 0,
                    radius2: option.image.shape.radius2 || 0,
                    angle: option.image.shape.angle,
                    displacement: option.image.shape.displacement,
                    stroke: new Stroke(option.image.shape.stroke),
                    rotation: option.image.shape.rotation,
                    rotateWithView: option.image.shape.rotateWithView,
                    scale: option.image.shape.scale,
                };
                trueOption = Object.assign(trueOption, { image: new RegularShape(shapeOption) });
            }
            if (option.image.icon) {
                trueOption = Object.assign(trueOption, { image: new Icon(option.image.icon) });
            }
        }
    }
    if (option.text) {
        var textOption = option.text.valueOf();
        if (option.text.fill) {
            textOption = Object.assign(trueOption, { fill: new Fill({ color: option.fill }) });
        }
        if (option.text.stroke) {
            textOption = Object.assign(trueOption, { stroke: new Stroke(option.text.stroke) });
        }
        if (option.text.backgroundFill) {
            textOption = Object.assign(trueOption, { backgroundFill: new Fill({ color: option.fill }) });
        }
        if (option.text.backgroundStroke) {
            textOption = Object.assign(trueOption, { backgroundStroke: new Stroke(option.text.backgroundStroke) });
        }
        trueOption = Object.assign(trueOption, { text: new Text(textOption) });
    }
    return new Style(trueOption);
}
/***
 * 创建一个Draw交互图层
 * @param option
 * @param style
 */
export function createDrawer(option, style) {
    var vectorSource = getVectorSource("NONE");
    var vectorLayer = new Vector({
        source: vectorSource,
        style: formatStyle(style),
        zIndex: style.zIndex
    });
    option.source = vectorSource;
    var draw = new Draw(option);
    return {
        type: option.type,
        draw: draw,
        layer: vectorLayer,
        source: vectorSource
    };
}
/***
 * 获取一个矢量layer的面积
 * 官网参考 https://openlayers.org/en/latest/apidoc/module-ol_sphere.html
 * @param layer {@link Vector} 传入的ol.Layer 对象
 * @param projection {@link String} 坐标系(非必填)，默认84坐标系-EPSG:4326(官方原方法中为 EPSG:3857)
 * @param radius {@link String} 曲率(非必填)，默认值为 6371008.8
 * @return {@link number} 返回面积默认单位 m²(平方米)
 */
export function getVectorArea(layer, projection, radius) {
    projection = projection || 'EPSG:4326';
    radius = radius || 6371008.8;
    var features = layer.getSource().getFeatures();
    var areas = new Array();
    for (var i = 0; i < features.length; i++) {
        var area = sphere.getArea(features[i].getGeometry(), { projection: projection, radius: radius });
        areas.push(area);
    }
    return areas;
}
/***
 * 传入两点坐标计算两点间的距离
 * 官网参考 https://openlayers.org/en/latest/apidoc/module-ol_sphere.html
 * @param startPoint 起始点坐标 [lat, lng]
 * @param endPoint 终点坐标 [lat, lng]
 * @param radius 曲率（非必填项， 默认84坐标系 ‘WGS84’）
 * @return {number} 距离（单位：米）
 */
export function getDistance(startPoint, endPoint, radius) {
    var distance = 0;
    distance = sphere.getDistance(startPoint, endPoint, radius);
    return distance;
}
/***
 * 传人一个线状layer对象，返回线的长度
 * 官网参考 https://openlayers.org/en/latest/apidoc/module-ol_sphere.html
 * @param layer 传入的ol.Layer 对象
 * @param projection 坐标系(非必填)，默认84坐标系-EPSG:4326(官方原方法中为 EPSG:3857)
 * @param radius 曲率(非必填)，默认值为 6371008.8
 * @return {number} 距离（单位：米）
 */
export function getLength(layer, projection, radius) {
    projection = projection || 'EPSG:4326';
    radius = radius || 6371008.8;
    var lengthList = new Array();
    var features = layer.getSource().getFeatures();
    for (var i = 0; i < features.length; i++) {
        var length_1 = sphere.getLength(features[i].getGeometry(), { projection: projection, radius: radius });
        lengthList.push(length_1);
    }
    return lengthList;
}
/***
 * 获取一个矢量图层的中心点
 * @param layer
 */
export function getCenterPoint(layer) {
    var extent = layer.getExtent();
    //获取边界区域的中心位置
    if (extent) {
        return getCenter(extent);
    }
}
export function getZoom(extend) {
    getWidth(extend);
}
/***
 * 要素转GeoJson
 * @param feature
 */
export function featureToGeoJson(feature) {
    return JSON.parse(new GeoJSON().writeFeature(feature));
}
/***
 * 添加弹窗组件
 * @param map {@link Map}
 * @param element {@link String} | {@link HTMLElement}
 */
export function addOverlay(map, option) {
    var overlay = new Overlay(option);
    map.addOverlay(overlay);
    return overlay;
}
