import * as util from "./olUtil";
/***
 * OlHelper 类
 *
 * 方便于创建OpenLayers实例，此类是OlUtil工具的实现，具体实现方式请移步{@link util}
 *
 * @author instanr
 * @since 2021-07-20
 * @version 1.0.0
 *
 *
 */
var OlHelper = /** @class */ (function () {
    function OlHelper(option, baseMap, tick, imagerySet) {
        var _a = util.createMap(option), map = _a.map, view = _a.view;
        this.map = map;
        this.view = view;
        this.baseMap = baseMap || "OSM";
        this.tick = tick || "";
        this.imagerySet = imagerySet;
        this.drawer = null;
        this.loadBaseLayer();
    }
    /***
     * 加载底图
     */
    OlHelper.prototype.loadBaseLayer = function () {
        var BLSOptions = this.formatBaseLayerSource();
        this.baseLayer = util.createTileLayer({
            sourceType: BLSOptions.sourceType,
            sourceOption: BLSOptions.sourceOption
        });
        this.map.addLayer(this.baseLayer);
    };
    OlHelper.prototype.formatBaseLayerSource = function () {
        var sourceType = "OSM";
        var sourceOption = null;
        if (this.baseMap === 'TDT') {
            sourceType = "XYZ";
            sourceOption = {
                // 地图来源： © <a href="https://www.tianditu.gov.cn/">天地图</a>
                attributions: '',
                crossOrigin: '*',
                url: "http://t0.tianditu.com/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=".concat(this.tick),
                zIndex: 0
            };
        }
        if (this.baseMap === 'OSM') {
            sourceType = "OSM";
            sourceOption = {
                attributions: ''
            };
        }
        if (this.baseMap === 'BING') {
            sourceType = "Bing";
            sourceOption = {
                attributions: '',
                key: this.tick,
                imagerySet: this.imagerySet
            };
        }
        return { sourceType: sourceType, sourceOption: sourceOption };
    };
    /***
     * 添加一个栅格图层
     * 实现方式： {@link util.createTileLayer}
     * @param option {@link createTileLayerOption}
     */
    OlHelper.prototype.addTileLayer = function (option) {
        var tileLayer = util.createTileLayer(option);
        this.map.addLayer(tileLayer);
        return tileLayer;
    };
    /***
     * 添加一个矢量图层
     * @param option
     */
    OlHelper.prototype.addVectorLayer = function (option) {
        var vectorLayer = util.createVectorLayer(option);
        this.map.addLayer(vectorLayer);
        return vectorLayer;
    };
    /***
     * 绘制图形
     * @param option {@link DrawThingsOption}
     * @param callBack {@link Function} return: {event, geoJson}
     * @return {@link DrawerSource}
     */
    OlHelper.prototype.drawThings = function (option, callBack) {
        if (this.drawer) {
            this.map.removeLayer(this.drawer.layer);
            this.map.removeInteraction(this.drawer.draw);
            this.drawer = null;
        }
        this.drawer = util.createDrawer(option.option, option.style);
        this.map.addLayer(this.drawer.layer);
        this.map.addInteraction(this.drawer.draw);
        this.drawer.draw.on("drawend", function (event) {
            var data = {
                event: event,
                geoJson: util.featureToGeoJson(event.feature)
            };
            callBack(data);
        });
        return this.drawer;
    };
    /***
     * 结束绘制，返回绘制信息
     *
     * @param clearLayer {@link Boolean} 是否清除已绘制的图层
     */
    OlHelper.prototype.stopDrawing = function (clearLayer) {
        if (this.drawer) {
            this.map.removeInteraction(this.drawer.draw);
            if (clearLayer) {
                this.map.removeLayer(this.drawer.layer);
            }
        }
        return this.drawer;
    };
    /***
     * view的animate方法(说实话这个方法写的有点SB)
     * @param option {@link @module(ol/View/AnimationOptions)}
     */
    OlHelper.prototype.flyToPoint = function (option) {
        this.view.animate(option);
    };
    /***
     * 添加弹窗
     * @param option
     */
    OlHelper.prototype.addOverlay = function (option) {
        return util.addOverlay(this.map, option);
    };
    OlHelper.prototype.changeBaseLayer = function (baseMap, tick, imagerySet) {
        this.baseMap = baseMap || "OSM";
        this.tick = tick || "";
        this.imagerySet = imagerySet;
        var BLSOptions = this.formatBaseLayerSource();
        var baseLayerSource = util.getTileSource(BLSOptions.sourceType, BLSOptions.sourceOption);
        this.baseLayer.setSource(baseLayerSource);
    };
    return OlHelper;
}());
export default OlHelper;
