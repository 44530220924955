//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import compare from '@/components/ol/compare';
import { getGeoServerApiParams } from '@/utils/geoserver-params';
import config from '@/config';
import { thematicDatas } from '@/assets/datas/datas.js';

export default {
    name: 'index',
    inject: ['hideLoading', 'showLoading'],
    components: {
        compare,
    },
    data() {
        return {
            selectedThematicType: '土壤氮',
            selectRemoteSensing: {
                titleLayer: {
                    left: null,
                    right: null,
                },
            },
            syncView: true, // 视角同步
            chooseYear: 2020,
            datas: {},
        };
    },
    computed: {
        thematicTypeList() {
            return this.$store.state['thematicMap'].thematicTypeList;
        },
        thematicLayersAndStylesObj() {
            return this.$store.state['thematicMap'].thematicLayersAndStylesObj;
        },
    },
    methods: {
        chooseYearLeft(item) {
            console.log('chooseYearLeft', item);
            let obj =
                this.thematicLayersAndStylesObj[this.selectedThematicType];
            if (!obj) {
                return;
            }
            let option = this.getOption(obj);
            this.addOrthoLayer(option, false);
        },
        chooseYearRight(item) {
            console.log('chooseYearRight', item);
            let obj =
                this.thematicLayersAndStylesObj[this.selectedThematicType];
            if (!obj) {
                return;
            }
            let option = this.getOption(obj);
            this.addOrthoLayer(option, true);
        },
        getOption(obj) {
            // 加载对应的专题数据
            let urlParams = getGeoServerApiParams(obj.layers, 'wms');
            let option = {
                sourceType: 'WMS',
                sourceOption: {
                    url: config.qyGeoApi + '?' + urlParams,
                    params: {
                        layers: obj.layers,
                        styles: obj.STYLES,
                        TILED: true,
                    },
                    serverType: 'geoserver',
                    // Countries have transparency, so do not fade tiles:
                    transition: 0,
                },
                option: {
                    zIndex: 11,
                    visible: true,
                },
            };
            return option;
        },
        handlerThematicTypeClick(item) {
            this.selectedThematicType = item;
            this.datas = thematicDatas[this.selectedThematicType];
            // 根据专题图分类获取专题图layers和STYLES
            let obj =
                this.thematicLayersAndStylesObj[this.selectedThematicType];
            if (!obj) {
                return;
            }
            let option = this.getOption(obj);
            this.addOrthoLayer(option, true);
            this.addOrthoLayer(option, false);
        },

        // 添加wms服务
        addOrthoLayer(option, isRight) {
            // 添加正射影像wms图
            if (isRight) {
                if (this.selectRemoteSensing.titleLayer.right) {
                    this.$refs.compare.mapRight.map.removeLayer(
                        this.selectRemoteSensing.titleLayer.right
                    );
                    this.selectRemoteSensing.titleLayer.right = null;
                }
                this.selectRemoteSensing.titleLayer.right =
                    this.$refs.compare.mapRight.addTileLayer(option);
            } else {
                if (this.selectRemoteSensing.titleLayer.left) {
                    this.$refs.compare.mapLeft.map.removeLayer(
                        this.selectRemoteSensing.titleLayer.left
                    );
                    this.selectRemoteSensing.titleLayer.left = null;
                }
                this.selectRemoteSensing.titleLayer.left =
                    this.$refs.compare.mapLeft.addTileLayer(option);
            }
        },
    },
    mounted() {
        // this.selectedThematicType = this.thematicTypeList[0];
        this.$nextTick(() => {
            this.$store.dispatch('thematicMap/setThematicLayersAndStylesObj', {
                year: this.chooseYear,
            });
            this.handlerThematicTypeClick(this.selectedThematicType);
        });
        this.hideLoading();
    },
};
