/**
 * 请求geoserver的接口参数工具类
 * 
 */
import signFormat from '@/utils/sign-format';
import config from '@/config';
import moment from 'moment';

// geoserver平台租户apiKey
const API_KEY = 'a68b8b3f894f4559b2fd82d2b74e3b44';

/**
 * 根据不同的接口名称，返回请求GeoServerApi标准的参数
 *
 * @param    请求参数
 * @apiName  接口名称
 */
export const getGeoServerApiParams = (param, apiName) => {
    let timeStamp = moment().format('yyyyMMDDHHmmss');
    let data = {};
    switch (apiName) {
        case 'wms':
            data = {
                tenantId: 'l0hqo4ep',
                operator: 'tobacco',
                timeStamp: timeStamp,
                tradeType: '2023',
                layers: param
            }
            break;
        case 'wfs':
            data = {
                tenantId: 'l0hqo4ep',
                operator: 'tobacco',
                timeStamp: timeStamp,
                tradeType: '2024',
                typeName: param,
            }
            break;
        default:
            break;
    }
    let signMsg = signFormat(data, API_KEY);
    return `tradeType=${data.tradeType}&tenantId=${data.tenantId}&operator=${data.operator}&timeStamp=${timeStamp}&signMsg=${signMsg}`;
}

export const statisticWithGroup = async (datas = {}) => {
    let timeStamp = moment().format('yyyyMMDDHHmmss');
    let data = {
        tenantId: 'l0hqo4ep',
        operator: 'tobacco',
        timeStamp: timeStamp,
        tradeType: '2029',
        layerId: datas.layers,
        group: JSON.stringify(datas.group),
        bandIndex: 0,
    };
    data.signMsg = signFormat(data, API_KEY);
    return await postData(config.qyGeoApi, data).then(res => {
        if (res.code === 200) {
            return res.data;
        }
    });
}

export const clipStatisticWithGroup = async (datas = {}) => {
    let timeStamp = moment().format('yyyyMMDDHHmmss');
    let data = {
        tenantId: 'l0hqo4ep',
        operator: 'tobacco',
        timeStamp: timeStamp,
        tradeType: '2030',
        layerId: datas.layers,
        group: JSON.stringify(datas.group),
        bandIndex: datas.bandIndex,
        geoJson: JSON.stringify(datas.geoJson),
    };

    let signMsg = signFormat(data, API_KEY);
    data.signMsg = signMsg;
    return await postData(config.qyGeoApi, data).then(res => {
        if (res.code === 200) {
            return res.data;
        }
    });
}

// POST method:
const postData = async (url = '', data = {}) => {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
}