//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "TimePlayer",
    props: {
        /***
         * 组件的总体宽度
         */
        width: {
            type: String,
            default: '500px'
        },
        /***
         * 组件的高度
         */
        height: {
            type: String,
            default: '50px'
        },
        /***
         * 时间部分的边框样式
         */
        border: {
            type: String,
            default: ''
        },
        /***
         * 时间部分的圆角样式
         */
        borderRadius: {
            type: String,
            default: '8px'
        },
        /***
         * 组件的背景颜色
         */
        background: {
            type: String,
            default: '#00009966'
        },
        /***
         * 组件的阴影
         */
        boxShadow: {
            type: String,
            default: '0 0 12px rgb(0, 153, 255) inset'
        },
        /***
         * 轮播按钮的文字，支持标签
         */
        playText: {
            type: String,
            default: '轮播'
        },
        label: {
            type: String,
            default: 'name'
        },
        /***
         * 暂停按钮的文字，支持标签
         */
        stopText: {
            type: String,
            default: '暂停'
        },
        /***
         * 重置按钮的文字，支持标签
         */
        resetText: {
            type: String,
            default: '复位'
        },
        /***
        * 重置初始状态
        */
       resetInitText: {
           tyep: String,
           default: '重置'
       },
        /***
         * 轮播的时间间隔
         */
        playDuration: {
            type: Number,
            default: 2500
        },
        /***
         * 是否可以轮回移动
         */
        circle: {
            type: Boolean,
            default: false
        },
        /***
         * 是否显示控制按钮
         */
        controlButton: {
            type: [Object, Boolean],
            default: () => {
                return {
                    player: false,
                    reset: false,
                    resetInit: false,
                }
            }
        },
        /***
         * 是否显示子集（月份）
         */
        showChildren: {
            type: Boolean,
            default: false
        },
        /***
         * 时间数组
         */
        dateList: {
            type: Array,
            default: () => []
        },
        /***
         * 月份是否与年份一起显示
         */
        inlineSub: {
            type: Boolean,
            default: false
        },
        /**
         * 年份的后缀名，默认为空“”
         */
        yearSuffixStr: {
            type: String,
            default: ""
        },
        /***
         * 在 inlineSub 为 true 时，年份和月份的连接符，默认是空格" "
         */
        subLinker: {
            type: String,
            default: " "
        },
        /**
         * 在 inlineSub 为 true 时，月份的后缀名，默认为空“”
         */
        monthSuffixStr: {
            type: String,
            default: ""
        }
    },
    computed: {
        showPlayer: function () {
            if (this.controlButton) {
                if (this.controlButton === true) {
                    return true;
                }
                return this.controlButton.player;
            }else {
                return false;
            }
        },
        showReset: function () {
            if (this.controlButton) {
                if (this.controlButton === true) {
                    return true;
                }
                return this.controlButton.reset;
            }else {
                return false;
            }
        },
        showResetInit: function () {
            if (this.controlButton) {
                if (this.controlButton === true) {
                    return true;
                }
                return this.controlButton.resetInit;
            }else {
                return false;
            }
        }
    },
    data() {
        return {
            playStatus: false,
            yearWidth: 105,
            yearPosition: 0,
            selectedYear: null,
            selectedYearIndex: null,
            lastMoveYearIndex: null,
            showYears: true,
            monthWidth: 50,
            monthPosition: 0,
            selectedMonth: null,
            selectedMonthIndex: 0,
            lastMoveMonthIndex: 0,
            playInterval: null,
            yearMonthList: []
        }
    },
    methods: {
        clickPlayButton: function () {
            this.playStatus = !this.playStatus;
            if (this.playStatus) {
                this.playInterval = setInterval(this.intervalFunction, this.playDuration);
            }else {
                clearInterval(this.playInterval);
                this.playInterval = null;
            }
        },
        intervalFunction: function () {
            let index;
            if (this.inlineSub) {
                index = this.selectedYearIndex;
                index++;
                if (index > this.yearMonthList.length - 1) {
                    index = 0;
                }
                this.chooseYear(this.yearMonthList[index], index);
            }else {
                if (this.showYears) {
                    index = this.selectedYearIndex;
                    index++;
                    if (index > this.dateList.length - 1) {
                        index = 0;
                    }
                    this.chooseYear(this.dateList[index], index);
                } else {
                    index = this.selectedMonthIndex;
                    index++;
                    if (index > this.selectedYear.children.length - 1) {
                        index = 0;
                    }
                    this.chooseMonth(this.selectedYear.children[index], index);
                }
            }
        },
        resetPlayer: function () {
            if (this.inlineSub) {
                this.chooseYear(this.yearMonthList[0], 0);
            }else {
                if (this.showYears) {
                    this.chooseYear(this.dateList[0], 0);
                } else {
                    this.chooseMonth(this.selectedYear.children[0], 0);
                }
            }
        },
        resetInitPlayer: function () {
            if (this.showYears) {
                this.chooseYear(null, null);
            }else {
                this.chooseMonth(null, null);
            }
        },
        moveLeft: function () {
            if (this.showYears) {
                this.leftYears();
            }else{
                this.leftMonth();
            }
        },
        leftYears: function () {
            let outWidth = this.$refs.timepicker.clientWidth;
            let width = this.$refs.years.clientWidth;
            if (this.circle && this.yearPosition === outWidth - width) {
                this.yearPosition = 0;
                return;
            }
            this.yearPosition -= this.yearWidth;
            if (this.yearPosition < outWidth - width) {
                this.yearPosition = outWidth - width;
            }
        },
        leftMonth: function () {
            let outWidth = this.$refs.monthContent.clientWidth;
            let width = this.$refs.scrollMonths.clientWidth;
            if (this.circle && this.monthPosition === outWidth - width) {
                this.monthPosition = 0;
                return;
            }
            this.monthPosition -= this.monthWidth;
            if (this.monthPosition < outWidth - width) {
                this.monthPosition = outWidth - width;
            }
        },
        moveRight: function () {
            if (this.showYears) {
                this.rightYears();
            }else {
                this.rightMonth();
            }
        },
        rightYears: function () {
            let outWidth = this.$refs.timepicker.clientWidth;
            let width = this.$refs.years.clientWidth;
            if (this.circle && this.yearPosition === 0) {
                this.yearPosition = outWidth - width;
                return;
            }
            let excess = this.yearWidth - (outWidth % this.yearWidth);
            if (this.yearPosition === outWidth - width) {
                this.yearPosition += excess;
            }else {
                this.yearPosition += this.yearWidth;
                if (this.yearPosition > 0) {
                    this.yearPosition = 0;
                }
            }
        },
        rightMonth: function () {
            let outWidth = this.$refs.monthContent.clientWidth;
            let width = this.$refs.scrollMonths.clientWidth;
            if (this.circle && this.monthPosition === 0) {
                this.monthPosition = outWidth - width;
                return;
            }
            let excess = this.monthWidth - (outWidth % this.monthWidth);
            if (this.monthPosition === outWidth - width) {
                this.monthPosition += excess;
            }else {
                this.monthPosition += this.monthWidth;
                if (this.monthPosition > 0) {
                    this.monthPosition = 0;
                }
            }
        },
        chooseYear: function (item, index) {
            this.selectedYear = item;
            this.$emit("chooseYear", item);
            let outWidth = this.$refs.timepicker.clientWidth;
            let showCount = (outWidth / this.yearWidth).toFixed(0);
            if ((outWidth / this.yearWidth) % 1 > 0 && (outWidth / this.yearWidth) % 1 < 0.5) {
                showCount ++;
            }
            let moveStep = index - (showCount - 2);
            if (moveStep > 0) {
                let backStep = index - this.selectedYearIndex;
                if (backStep > 0){
                    this.yearPosition = 0;
                    for (let i = 0; i < moveStep; i++) {
                        this.leftYears();
                    }
                    this.lastMoveYearIndex = index;
                }
            }
            if (this.lastMoveYearIndex - index > (showCount - 3)) {
                if (index > 0) {
                    let backStep = (this.lastMoveYearIndex - index) - (showCount - 3);
                    for (let i = 0; i < backStep; i++) {
                        this.rightYears();
                    }
                    this.lastMoveYearIndex = this.lastMoveYearIndex - backStep;
                }else {
                    this.lastMoveYearIndex = 0;
                    this.yearPosition = 0;
                }
            }
            this.selectedYearIndex = index;
        },
        unfoldMonth: function (item) {
            this.selectedYear = item;
            this.showYears = false;
            item.opening = true;
            if (this.playStatus) {
                this.clickPlayButton();
            }
        },
        foldMonth: function () {
            this.showYears = true;
            this.selectedYear.opening = false;
        },
        chooseMonth: function (item, index) {
            this.selectedMonth = item;
            let data = JSON.parse(JSON.stringify(this.selectedYear));
            data.children = item;
            this.$emit("chooseMonth", data);
            let outWidth = this.$refs.monthContent.clientWidth;
            let showCount = (outWidth / this.monthWidth).toFixed(0);
            if ((outWidth / this.monthWidth) % 1 > 0 && (outWidth / this.monthWidth) % 1 < 0.5) {
                showCount ++;
            }
            let moveStep = index - (showCount - 2);
            if (moveStep > 0) {
                let backStep = index - this.selectedMonthIndex;
                if (backStep > 0){
                    this.monthPosition = 0;
                    for (let i = 0; i < moveStep; i++) {
                        this.leftMonth();
                    }
                    this.lastMoveMonthIndex = index;
                }
            }
            if (this.lastMoveMonthIndex - index > (showCount - 3)) {
                if (index > 0) {
                    let backStep = (this.lastMoveMonthIndex - index) - (showCount - 3);
                    for (let i = 0; i < backStep; i++) {
                        this.rightMonth();
                    }
                    this.lastMoveMonthIndex = this.lastMoveMonthIndex - backStep;
                }else {
                    this.lastMoveMonthIndex = 0;
                    this.monthPosition = 0;
                }
            }
            this.selectedMonthIndex = index;
        },
        formatYearMonthList: function () {
            let list = [];
            this.dateList.forEach(item => {
                if (item.children && item.children.length > 0) {
                    item.children.forEach(children => {
                        // 将原数据中的数据拷贝到新节点中
                        let data = {};
                        data[this.label] = item[this.label] + this.subLinker + children[this.label] + this.monthSuffixStr;
                        for (let key in children) {
                            if (key !== this.label) {
                                data[key] = children[key];
                            }
                        }
                        data['parent'] = JSON.parse(JSON.stringify(item));
                        data['parent'].children = null;
                        data['trueLabel'] = children[this.label];
                        list.push(data);
                    })
                }else {
                    let data = JSON.parse(JSON.stringify(item));
                    data['trueLabel'] = item[this.label];
                    list.push(data);
                }
            });
            this.yearMonthList = list;
        }
    },
    mounted() {
        this.formatYearMonthList();
    }
}
