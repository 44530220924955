//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OlHelper from '@/assets/scripts/openlayers-helper/ol-helper';
import config from '@/config';
import yearPicker from '../YearPicker.vue';

export default {
    name: 'Compare',
    props: {
        /***
         * 视角同步
         */
        syncView: {
            type: Boolean,
            default: true,
        },
        minLeft: {
            type: Number,
            default: 760,
        },
        minRight: {
            type: Number,
            default: 760,
        },
        
    },
    components: {
        yearPicker,
    },
    data() {
        return {
            mapLeft: null,
            mapRight: null,
            overlay: null,
            middleLine: {
                position: 0,
            },
            body: {
                offsetLeft: 0,
                width: 0,
                left: 0,
                right: 0,
            },
            moveButtonTop: 0,
            dateList: [{ name: '2015' }],
            dateList1: [{ name: '2020' }],
        };
    },
    watch: {
        syncView: {
            handler: function () {
                if (this.syncView) {
                    this.mapMoveEnd({
                        map: this.mapLeft.map,
                    });
                }
            },
            deep: true,
        },
    },
    methods: {
        chooseYearLeft(item) {
            this.$emit('chooseYearLeft', item);
        },
        chooseYearRight(item) {
            this.$emit('chooseYearRight', item);
        },
        renderMap: function () {
            // 初始化地图大小
            let clientWidth = (this.body.width = this.$refs.main.clientWidth);
            this.middleLine.position = clientWidth / 2 - 2;
            this.body.left = this.body.right = clientWidth / 2;
            this.body.offsetLeft = this.$refs.main.offsetLeft;
            this.$nextTick(() => {
                this.mapLeft = new OlHelper(
                    {
                        map: {
                            target: 'mapLeft',
                        },
                        view: {
                            center: [101.3983154296875, 24.81208186007361],
                            projection: 'EPSG:4326',
                            zoom: 7,
                            maxZoom: 19,
                        },
                    },
                    'TDT',
                    config.tdtToken
                );
                this.mapRight = new OlHelper(
                    {
                        map: {
                            target: 'mapRight',
                        },
                        view: {
                            center: [0, 0],
                            projection: 'EPSG:4326',
                            zoom: 5,
                            maxZoom: 19,
                        },
                    },
                    'TDT',
                    config.tdtToken
                );
                this.mapLeft.map.on('moveend', this.mapMoveEnd);
                this.mapRight.map.on('moveend', this.mapMoveEnd);

                // 初始化加载云南省边界

            });
            // 初始化时间轴
            this.$refs.yearPickerLeft.resetPlayer();
            this.$refs.yearPickerRight.resetPlayer();
        },
        lineMD: function () {
            document.addEventListener('mousemove', this.mouseMove);
        },
        mouseMove: function (e) {
            if (
                this.body.offsetLeft + this.minLeft < e.clientX &&
                e.clientX <
                    this.body.offsetLeft + this.body.width - this.minRight
            ) {
                this.middleLine.position = e.clientX - this.body.offsetLeft;
            }
        },
        lineMU: function () {
            document.removeEventListener('mousemove', this.mouseMove);
            this.calcMap();
        },
        lineMO: function (e) {
            this.moveButtonTop = e.y;
        },
        calcMap: function () {
            this.body.left = this.middleLine.position;
            this.body.right = this.body.width - this.body.left;
            this.$nextTick(() => {
                this.mapLeft.map.updateSize();
                this.mapRight.map.updateSize();
            });
        },
        mapMoveEnd: function (e) {
            if (this.syncView) {
                if (e.map.ol_uid === this.mapLeft.map.ol_uid) {
                    this.mapRight.view.setZoom(this.mapLeft.view.getZoom());
                    this.mapRight.view.setCenter(this.mapLeft.view.getCenter());
                }
                if (e.map.ol_uid === this.mapRight.map.ol_uid) {
                    this.mapLeft.view.setZoom(this.mapRight.view.getZoom());
                    this.mapLeft.view.setCenter(this.mapRight.view.getCenter());
                }
            }
        },
        resize: function () {
            let clientWidth = (this.body.width = this.$refs.main.clientWidth);
            this.middleLine.position = clientWidth / 2 - 1;
            this.body.left = this.body.right = clientWidth / 2;
            this.body.offsetLeft = this.$refs.main.offsetLeft;
            this.calcMap();
        },
    },
    mounted() {
        this.renderMap();
    },
};
